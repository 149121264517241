// Generated by https://quicktype.io

export interface StationsResponse {
  nhits: number;
  parameters: Parameters;
  records: Record[];
  facet_groups: FacetGroup[];
}

export interface FacetGroup {
  facets: Facet[];
  name: string;
}

export interface Facet {
  count: number;
  path: string;
  state: State;
  name: string;
}

export enum State {
  Displayed = 'displayed',
}

export interface Parameters {
  dataset: Dataset;
  timezone: string;
  rows: number;
  start: number;
  format: string;
  facet: string[];
}

export enum Dataset {
  Kontaktadressen = 'kontaktadressen',
}

export interface Record {
  datasetid: Dataset;
  recordid: string;
  fields: Fields;
  geometry: Geometry;
  record_timestamp: string;
}

export interface Fields {
  nummer?: string;
  land: Land;
  bezeichnung_offiziell: string;
  service: string;
  lod: string;
  firma: Firma;
  abkuerzung: string;
  ort: string;
  adresse?: string;
  plz: number;
  geopos: number[];
  bpuic: number;
  meteo: string;
  didok: number;
  tu_nummer: number;
  mail?: string;
  stationsbezeichnung: string;
  postfach?: string;
}

export enum Firma {
  CffSa = 'CFF SA',
  SbbAg = 'SBB AG',
}

export enum Land {
  Ch = 'CH',
}

export interface Geometry {
  type: Type;
  coordinates: number[];
}

export enum Type {
  Point = 'Point',
}
