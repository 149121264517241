import { render, staticRenderFns } from "./SbbLogo.vue?vue&type=template&id=b67cf306&scoped=true&"
import script from "./sbb-logo.ts?vue&type=script&lang=ts&"
export * from "./sbb-logo.ts?vue&type=script&lang=ts&"
import style0 from "./sbb-logo.css?vue&type=style&index=0&id=b67cf306&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b67cf306",
  null
  
)

export default component.exports